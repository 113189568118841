import { template as template_6c5e765a05414d6082948d7bd9a53e35 } from "@ember/template-compiler";
const FKLabel = template_6c5e765a05414d6082948d7bd9a53e35(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
