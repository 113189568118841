import { template as template_4c61426a60d1486eae0088e55cc455e8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_4c61426a60d1486eae0088e55cc455e8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
