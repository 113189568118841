import { template as template_7d1a48c25ea34d93814d988e219770ba } from "@ember/template-compiler";
const FKControlMenuContainer = template_7d1a48c25ea34d93814d988e219770ba(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
