import { template as template_330a02f5be234abfb78731351a6c9840 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_330a02f5be234abfb78731351a6c9840(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
